import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Question } from '@/interfaces'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
@Component({
  name: 'GtrSessionSurveyView',
  computed: {
    ...mapState('event', ['event']),
    ...mapState('surveys', [
      'sessionChangelog',
      'currentlySessionDeployedLiveUUID',
      'currentSessionDevUUID',
      'sessionSurvey',
      'generalSurvey',
      'keynoteSurvey'
    ]),
    ...mapState('sessions', ['sessions'])
  }
})
export default class GtrSessionSurveyView extends Vue {
  sessionSurvey!: Record<string, any>
  generalSurvey!: Record<string, any>
  keynoteSurvey!: Record<string, any>

  private sessionHeaders = [
    { text: 'Start Time', value: 'start_date' },
    { text: 'End Time', value: 'end_date' },
    { text: 'Name', value: 'name', searchable: true },
    { text: 'Category', value: 'category', searchable: true },
    { text: 'Speaker', value: 'speaker', searchable: true },
    { text: 'Credits', value: 'credits' },
    { text: 'Actions', value: 'actions' }
  ];

  private generalOrKeynoteHeaders = [
    { text: 'Question text', value: 'question_text' },
    { text: 'Applies To', value: 'applies_to' },
    { text: 'Required', value: 'required' }
  ]

  data () {
    return {
      language: 'en',
      loading: false,
      tableHeaders: [
        this.generalOrKeynoteHeaders,
        this.sessionHeaders
      ],
      questionHeaders: [
        { text: 'Question text', value: 'question_text' },
        { text: 'Correct Answer', value: 'correct_answer' },
        { text: 'Required', value: 'required' }
      ],
      sessionSearch: null,
      sessionsAndQuestions: [],
      showAddSessionQuestionForm: false,
      editing: false,
      required_percentage: null,
      tableUpdated: false,
      surveyVersion: null,
      sessionQuestionModalOpen: false,
      activeSession: {
        name: '',
        questions: []
      },
      activeTab: 0
    }
  }

  beforeRouteLeave (from, to, next) {
    if (this.$data.tableUpdated) {
      if (confirm('Do you really want to leave? You have unsaved changes!')) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  async mounted () {
    await this.fetchSessionChangelog()
    await this.fetchCurrentSessionDevUUID()
    await this.fetchCurrentlySessionDeployedLiveUUID()
    await this.fetchSessions()
    await this.fetchSessionSurvey()
    await this.fetchGeneralSurvey()
    await this.fetchKeynoteSurvey()
  }

  @Watch('sessionSurvey')
  onSessionSurveyChange (payload: any) {
    if (payload.page_data) {
      this.$data.required_percentage = payload.page_data[0].required_percentage ?? null
      this.populateSessionQuestions(payload.page_data[0].fields)
    }
  }

  @Watch('sessions')
  onSessionsChange (payload: any) {
    if (payload) {
      this.populateSessions(payload)
      if (this.sessionSurvey?.page_data?.[0]?.fields) {
        this.populateSessionQuestions(this.sessionSurvey.page_data[0].fields)
      }
    }
  }

  @Watch('surveyVersion')
  async onSurveyVersionChange (payload: any) {
    if (payload !== 'default') {
      const response = await this.$store.dispatch('surveys/getSessionSurveyByUUID', { event_uuid: this.$route.params.event_uuid, survey_uuid: payload })
      const changeLogPageData = response.data.page_data[0]
      if (changeLogPageData) {
        this.populateSessionQuestions(changeLogPageData.fields)
        this.$data.required_percentage = changeLogPageData.required_percentage ?? null
      }
      Container.get(Notification).success('Survey version successfully changed.')
    }
  }

  setActiveSession (session): void {
    this.$data.activeSession = session
  }

  clearActiveSession (): void {
    this.$data.activeSession = {
      name: '',
      questions: []
    }
  }

  get generalQuestions () {
    if (this.generalSurvey.page_data) {
      return this.generalSurvey?.page_data[0]?.fields ?? []
    } else {
      return []
    }
  }

  get keynoteQuestions () {
    if (this.keynoteSurvey.page_data) {
      return this.keynoteSurvey?.page_data[0]?.fields ?? []
    } else {
      return []
    }
  }

  get headers () {
    const headers = (this.$data.activeTab === 1) as unknown as number
    return this.$data.tableHeaders[headers]
  }

  // navigate to the questions for the selected session.
  async navigateToSessionQuestions (): Promise<void> {
    if (this.$data.activeTab === 0) {
      await this.$router.push({ name: 'level-two.modules.evals.surveys.general.questions' })
    } else if (this.$data.activeTab === 1) {
      await this.$router.push({ name: 'level-two.modules.evals.surveys.session.questions' })
    } else if (this.$data.activeTab === 2) {
      await this.$router.push({ name: 'level-two.modules.evals.surveys.keynote.questions' })
    }
  }

  private populateSessions (sessionArray: any) {
    const sessions = sessionArray.reduce((activeSessions: any[], session: any) => {
      if (session.active) {
        activeSessions.push({
          start_date: session.start_date,
          end_date: session.end_date,
          name: session.name,
          category: session.category,
          credits: session.credits,
          speaker: session.session_speakers?.[0]?.speaker_condensed.first_name + ' ' + session.session_speakers?.[0]?.speaker_condensed.last_name ?? '',
          uuid: session.uuid,
          questions: []
        })
      }
      return activeSessions
    }, [])
    sessions.sort((a, b) => (a.start_date <= b.start_date) ? -1 : 1)
    this.$data.sessionsAndQuestions = sessions
  }

  private sessionUUIDLoopHandler (session_uuids: string[], question: Question) {
    for (let i = 0; i < session_uuids.length; i++) {
      const [session] = this.$data.sessionsAndQuestions.filter(s => s.uuid === session_uuids[i])
      if (!session.questions) {
        session.questions = []
      }
      session.questions.push(question)
    }
  }

  // TODO(zb): the if-else on 153:159 is kind of hacky and I think can be done better.
  //  For now I'm just focused on solving the problem in GTR-908.
  //  It seems like there are instances where the session_uuid is an array and others where it is a string.
  //  I'm not sure why this is the case, but I'm going to try to handle both cases here.
  private populateSessionQuestions (fields): void {
    const questions = fields
    this.$data.sessionsAndQuestions.forEach(session => { session.questions.length = 0 }) // clear out old questions
    questions.forEach(question => {
      if (Array.isArray(question.session_uuid)) {
        this.sessionUUIDLoopHandler(question.session_uuid, question)
      } else {
        const [session] = this.$data.sessionsAndQuestions.filter(s => s.uuid === question.session_uuid)
        if (!session.questions) {
          session.questions = []
        }
        if (!question.range) {
          question.range = { start: 0, end: 0, other: [] }
        }
        session.questions.push(question)
      }
    })
  }

  private async fetchSessionChangelog () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getSessionChangelog', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCurrentSessionDevUUID () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getCurrentSessionDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'session' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCurrentlySessionDeployedLiveUUID () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getCurrentlySessionDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'session' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchSessionSurvey () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getSessionSurvey', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchGeneralSurvey () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getGeneralSurvey', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchKeynoteSurvey () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getKeynoteSurvey', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchSessions () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })
      this.populateSessions(response.data.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  openSessionQuestionsModal (session) {
    this.setActiveSession(session)
    this.$data.sessionQuestionModalOpen = true
  }

  closeSessionQuestionsModal () {
    this.$data.sessionQuestionModalOpen = false
    setTimeout(() => { this.clearActiveSession() }, 1000)
  }
}
